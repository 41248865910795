import { graphql } from 'gatsby'
import React from 'react'

import Breadcrumb from '../components/breadcrumb'
import Heading from '../components/heading'
import Image from '../components/image'
import Layout from '../components/layout'
import Link from '../components/link'
import List from '../components/list'
import ListItem from '../components/list-item'
import Paragraph from '../components/paragraph'
import SEO from '../components/seo'


export const frontmatter = {
  body: 'Seasons',
  slug: '/seasons/',
  title: 'Seasons',
}

export const query = graphql`
  query SeasonsPageQuery {
    ...SiteInfo
    imageMain: file(relativePath: { eq: "seasons.jpg" }) { ...ImageMain }
    results: postgres {
      seasons: allSeasonsList(orderBy: SORT_ASC) {
        ...Season
      }
    }
  }
`

export default ({ data }) => {
  const { links, meta } = data.site.siteMetadata
  const { seasons } = data.results
  const { entity } = meta
  const imageMain = data.imageMain.childImageSharp.fixed

  const page = 'Seasons'
  const title = `${entity.singular} ${page}`
  const description = `
    Each season of the year is unique and different from the rest. Certain
    Fragrance Notes and Accords provide scents to match, and highlight, what
    makes a particular season special.
  `
  const keywords = title.split(/\W+/)
  const trail = [
    {
      name: page,
      slug: links.seasons,
    },
  ]
  const schema = {
    breadcrumb: trail,
    description,
    image: imageMain.src,
    keywords,
    name: title,
    things: seasons,
    slug: links.seasons,
  }

  return (
    <Layout>
      <SEO
        description={description}
        keywords={keywords}
        schema={schema}
        title={title}
      />
      <Breadcrumb trail={trail} />

      <Heading>{title}</Heading>

      <Image
        alt={title}
        credit="seasons.jpg"
        fixed={imageMain}
      />

      <Paragraph>
        {description}
      </Paragraph>

      <List>
        {seasons.map(season => (
          <ListItem key={season.id}>
            <Link href={season.slug}>
              {season.name}
            </Link>
          </ListItem>
        ))}
      </List>

      <Breadcrumb trail={trail} />
    </Layout>
  )
}
